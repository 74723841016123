<template>
  <div class="table">
    <div class="head">
      <div class="item">Игра</div>
      <div class="item">Игрок</div>
      <div class="item center">Ставка</div>
      <div class="item center">Коэф.</div>
      <div class="item right">Выигрыш</div>
    </div>
    <transition-group name="fade" tag="div">
      <div v-for="game in data" :key="game.id" class="game">
        <div class="item">
          <div class="badge" v-if="game.game === 'dice_classic'">
            <img alt="Dice" src="@/assets/icons/dice-history-blue.svg" />
            Dice
          </div>
        </div>
        <div class="item">
          <img
            :alt="game.user.name"
            class="user"
            :src="cdn + game.user.avatar"
          />
          {{ game.user.name }}
        </div>
        <div class="item center">{{ convertAmount(game.bet, "rub") }}₽</div>
        <div class="item center">x{{ game.coeff | fixedTo }}</div>
        <div
          class="item right"
          :class="{
            'text-success': game.winSum > 0,
            'text-danger': game.winSum <= 0,
          }"
        >
          {{ convertAmount(game.winSum, "rub") }}₽
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { uniqueId } from "lodash";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      broadcast: null,
      data: [],
      cdn: this.CDN_URL,
    };
  },

  computed: mapGetters("user", ["convertAmount"]),

  destroyed() {
    if (this.broadcast !== null) this.broadcast.unsubscribe();
  },

  mounted() {
    this.broadcast = this.$centrifuge.subscribe("live:feed", ({ data }) =>
      this.handle(data)
    );

    this.broadcast
      .history()
      .then(({ publications }) =>
        publications.forEach(({ data }) => this.handle(data))
      );
  },

  methods: {
    handle(data) {
      this.data.unshift({ ...data, id: uniqueId() });
      this.data = this.data.splice(0, 10);
    },
  },

  filters: {
    fixedTo(value) {
      return Number(value).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  background-color: white;
  border-radius: 6px;
  margin-bottom: 25px;
  max-height: 678px;
  overflow: hidden;
  .head {
    display: flex;
    border-bottom: 1px solid #f4f2fb;
    padding: 0 20px;
    .item {
      width: calc(100% / 5);
      padding: 20px;
      color: #a3afc9;
      font-weight: 500;
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
    }
  }
  .game {
    display: flex;
    border-bottom: 1px solid #f4f2fb;
    padding: 0 20px;
    align-items: center;
    img.user {
      width: 45px;
      height: 45px;
      border-radius: 6px;
      margin-right: 15px;
    }
    &:last-child {
      border-bottom: 0px;
    }
    .item {
      width: calc(100% / 5);
      color: #3a3e46;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding: 8px 20px;
      &.right {
        justify-content: flex-end;
      }
      &.center {
        justify-content: center;
      }
      &.text-success {
        color: #82bc38;
      }
      .badge {
        // border: 1px solid #eef0f6;
        // border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 10px 15px;
        img {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
